import React, { useContext } from "react";

import AuthContext from "../../../context/AuthContext";
import Sidebar from "../Sidebar";
import "./styles.css";

const UserProfile = () => {
    const { user } = useContext(AuthContext); 

  return(
    <div className="dFlex">
        <Sidebar />
        <div className="profileContainer">
            <div class="user-info-card">
                <div className="profile-picture">
                    {user?.name ? user?.name.charAt(0).toUpperCase() : "TC"}
                </div>
                <div class="user-details">
                    <h2>{user?.name || '--'}</h2>
                    <p class="email">{user?.email || '--'}</p>
                    <p class="company">Company: {user?.companyName || '-'}</p>
                    <p class="contact">Contact: {user?.contactNumber || '-'}</p>
                </div>
                <button className="edit-btn">Edit Profile</button>
            </div>
        </div>
    </div>
  )
};

export default UserProfile;
