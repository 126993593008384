import React from "react";
import "./styles.css";
// import AVATAR_PLACEHOLDER from ''
const Sidebar = () => {

  return(
    <div class="sidebarContainer">
        <div class="profileImage"><img src="/assets/avatar-placeholder.jpg" alt="Manual Testing Benefits" loading="lazy" /></div>
        <div class="menuItem active">My Profile</div>
        <div class="menuItem">My Blogs</div>
        <div class="menuItem">My Quizes</div>
        <div class="menuItem">Ask About <br />QA/Automation</div>
        <div class="menuItem">Certificates</div>
        <div class="menuItem">Tools</div>
        <div class="logout">Logout</div>
    </div>
  )
};

export default Sidebar;
