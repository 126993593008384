import React, { useState } from "react";
import { jsPDF } from "jspdf";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { toast } from 'react-hot-toast';
import "./styles.css";

const EstimatesGenerator = () => {
  const [testingIterations, setTestingIterations] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [totalEffort, setTotalEffort] = useState(null);
  const [numFeatures, setNumFeatures] = useState("");
  const [numPages, setNumPages] = useState("");

  const handleSubmit = () => {
    const effort = numFeatures * numPages * testingIterations;
    setTotalEffort(effort);
  };

  const generatePDF = async () => {
    const doc = new jsPDF();
    doc.setFontSize(16);
    doc.text("Effort Calculation Report", 20, 20);
    doc.setFontSize(12);
    doc.text(`Selected Type: ${selectedOption}`, 20, 40);
    doc.text(`No of Features: ${numFeatures}`, 20, 50);
    doc.text(`No of Pages: ${numPages}`, 20, 60);
    doc.text(`Testing Iterations: ${testingIterations}`, 20, 70);
    doc.text(`Total Efforts: ${totalEffort}`, 20, 80);
    doc.text("Lorem ipsum dolor sit amet, consectetur adipiscing elit.", 20, 100);
    
    // Generate unique filename
    const uniqueId = Date.now() + '-' + Math.random().toString(36).substr(2, 9);
    const filename = `Effort_Report_${uniqueId}.pdf`;
    
    // Convert PDF to ArrayBuffer instead of Blob
    const pdfBuffer = doc.output('arraybuffer');
    doc.save(filename);

    // Upload to S3
    try {
      const s3Client = new S3Client({
        region: "us-east-1",
        credentials: {
          accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
        },
        endpoint: `https://s3.us-east-1.amazonaws.com`,
        forcePathStyle: true
      });

      const uploadParams = {
        Bucket: "thoughtcoders.tech",
        Key: `estimates/${filename}`,
        Body: pdfBuffer,
        ContentType: 'application/pdf'
      };

      await s3Client.send(new PutObjectCommand(uploadParams));
      console.log('Successfully uploaded to S3');
      toast.success('Successfully uploaded to S3 Bucket');
    } catch (error) {
      console.error('Error uploading to S3:', error);
    }
  };

  return (
    <div className="generatorContainer">
      <div className="form-group">
        <label>Select Application Type</label>
        <select
          className="dropdown"
          value={selectedOption}
          onChange={(e) => setSelectedOption(e.target.value)}
        >
          <option value="">Select</option>
          <option value="Desktop">Desktop</option>
          <option value="Mobile">Mobile</option>
          <option value="Web">Web</option>
          <option value="API">API</option>
        </select>
      </div>

      {["Desktop", "Mobile"].includes(selectedOption) && (
        <div className="form-fields">
          <div className="input-group">
            <label>No of Features</label>
            <input
              type="number"
              min="1"
              className="input-field"
              value={numFeatures}
              onChange={(e) => setNumFeatures(e.target.value)}
            />
          </div>

          <div className="input-group">
            <label>No of Pages</label>
            <input
              type="number"
              min="1"
              className="input-field"
              value={numPages}
              onChange={(e) => setNumPages(e.target.value)}
            />
          </div>

          <div className="input-group">
            <label>Testing Iterations</label>
            <input
              type="number"
              min="1"
              className="input-field"
              value={testingIterations}
              onChange={(e) => setTestingIterations(e.target.value)}
            />
          </div>
        </div>
      )}

      {["Desktop", "Mobile"].includes(selectedOption) && (
        <button
          className={`submit-button ${numFeatures && numPages && testingIterations ? "enabled" : "disabled"}`}
          onClick={handleSubmit}
          disabled={!numFeatures || !numPages || !testingIterations}
        >
          Submit
        </button>
      )}

      {totalEffort !== null && (
        <>
            <h1 className="result">Total Efforts: {totalEffort} Hrs.</h1>
            <button className="pdf-button" onClick={generatePDF}>Download PDF</button>
        </>
      )}
    </div>
  )
};

export default EstimatesGenerator;